<template>
	<div>
		<div class="col-md-12">
			<div class="row" style="margin-top:20px">
				<div class="col-md-6">
					<h3 class="page-header">
						<i class="fa fa-linode animated bounceInDown show-info"></i>
						Add Real State Property
					</h3>
				</div>
				<div class="col-md-6 ">
					<div class="row">

					</div>
				</div>
			</div>
		</div>
		
			<div class="panel panel-cascade">
				<div class="panel-body">
					<div class="form-horizontal cascde-forms">
						<div class="col-md-12">

							<div class="form-group row">
								<div class="col-md-3">
									<label class="col-form-label control-label">Title<span
											style="color: rgba(255, 0, 0, 0.67)">*</span></label>
								</div>
								<div class="col-md-6">
									<input type="text" class="form-control form-cascade-control input-small"
										v-model="form.title">
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label class="col-form-label control-label">Street<span
											style="color: rgba(255, 0, 0, 0.67)">*</span></label>
								</div>
								<div class="col-md-6">
									<input type="text" class="form-control form-cascade-control input-small"
										v-model="form.street">
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label class="col-form-label control-label">Landmark<span
											style="color: rgba(255, 0, 0, 0.67)">*</span></label>
								</div>
								<div class="col-md-6">
									<input type="text" class="form-control form-cascade-control input-small"
										v-model="form.landmark">
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label class="col-form-label control-label">City<span
											style="color: rgba(255, 0, 0, 0.67)">*</span></label>
								</div>
								<div class="col-md-6">
									<input type="text" v-model="form.city"
										class="form-control form-cascade-control input-small">
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label class="col-form-label control-label">Pincode</label>
								</div>
								<div class="col-md-6">
									<input type="text" v-model="form.pincode"
										class="form-control form-cascade-control input-small">
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label class="col-form-label control-label">State<span
											style="color: rgba(255, 0, 0, 0.67)">*</span></label>
								</div>
								<div class="col-md-6">
									<select class="form-control form-cascade-control input-small" v-model="form.state">
										<option value="">Select State</option>
										<option v-for="(item) in getmastertype(MASTER_STATES)" :key="item.labelid" :value="item.labelid">{{
											item.label }}</option>
									</select>
								</div>
							</div>
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-3 p-0">
										<label class="col-form-label control-label">Youtube Link</label>
									</div>
									<div class="col-md-6">
										<div>
											<input type="text" v-model="youtubeLink"
												class="form-control form-cascade-control input-small">
										</div>
										<div class="mt-10 row">
											<div class="col-md-6 row" v-for="(link, index) in youtubeLinks" :key="index">
												<div class="col-md-11" style="padding-right: 0px;">
													<div class="form-control form-cascade-control input-small disabled">{{ link }}</div>
												</div>
												<div class="col-md-1 p-0">
													<button type="button" class="btn btn-danger btn-sm "
														@click="removeYoutubeLink(index)">X</button>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-3">
										<button type="button" class="btn btn-primary" @click="saveYoutubeLink()">Save
											Link</button>
									</div>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label class="col-form-label control-label">Contact Person</label>
								</div>
								<div class="col-md-6">
									<input type="text" v-model="form.contactpersonname"
										class="form-control form-cascade-control input-small">
								</div>
							</div>
							<div class="col-md-12">
								<div class="form-group row">
									<div class="col-md-3 p-0">
										<label class="col-form-label control-label">Contact No.</label>
									</div>
									<div class="col-md-6">
										<div>
											<input type="number" v-model="contact_no"
												class="form-control form-cascade-control input-small">
										</div>
										<div class="mt-10 row">
											<div class="col-md-6 row" v-for="(contact, index) in contact_nos" :key="index">
												<div class="col-md-11" style="padding-right: 0px;">
													<div class="form-control form-cascade-control input-small disabled">{{ contact }}</div>
												</div>
												<div class="col-md-1 p-0">
													<button type="button" class="btn btn-danger btn-sm "
														@click="removeContactNo(index)">X</button>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-3">
										<button type="button" class="btn btn-primary" @click="saveContactNo()">Save
											No.</button>
									</div>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label class="col-form-label control-label">Price</label>
								</div>
								<div class="col-md-6">
									<input type="number" v-model="form.price"
										class="form-control form-cascade-control input-small">
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label class="col-form-label control-label">Square Yard (Gaz)</label>
								</div>
								<div class="col-md-6">
									<input type="number" v-model="form.sizeinguz"
										class="form-control form-cascade-control input-small">
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label class="col-form-label control-label">Front Size(feet)</label>
								</div>
								<div class="col-md-6">
									<input type="number" v-model="form.frontinfoot"
										class="form-control form-cascade-control input-small">
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label class="col-form-label control-label">Length (feet)</label>
								</div>
								<div class="col-md-6">
									<input type="number" v-model="form.lengthinfoot"
										class="form-control form-cascade-control input-small">
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label class="col-form-label control-label">Constructed</label>
								</div>
								<div class="col-md-6">
									<select class="form-control form-cascade-control input-small"
										v-model="form.constructed">
										<option :value="1">Yes</option>
										<option :value="0">No</option>
									</select>

								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-3">
									<label class="col-form-label control-label">KDA Approved</label>
								</div>
								<div class="col-md-6">
									<select class="form-control form-cascade-control input-small" v-model="form.iskda">
										<option :value="1">Yes</option>
										<option :value="0">No</option>
									</select>

								</div>
							</div>

							<div class="form-group row">
								<div class="col-md-3">
									<label class="col-form-label control-label">Description</label>
								</div>
								<div class="col-md-6">
									<textarea class="form-control form-cascade-control input-small"
										v-model="form.description" cols="30" rows="3"></textarea>
									<span class="invalid-feedback" role="alert">
										<strong></strong>
									</span>
								</div>
							</div>
							<div class="col-md-12  text-center">
								<div class="row">
									<div class="col-md-4"></div>
									<div class="col-md-2">
										<input type="button" class="btn btn-primary" value="Submit"
											@click="onSubmit()" />
									</div>
									<div class="col-md-2">
										<input type="button" class="btn btn-danger" @click="cancel()" value="Cancel" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

	
	</div>
</template>

<script>
import axios from 'axios';
// import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue';
export default {
	mixins: [Constants],
	props:['editproperty'],
	components: {

	},
	data() {
		return {
			form: {
				id: 0,
				name: '',
				street: '',
				landmark: '',
				city: '',
				pincode: '',
				youtubelink:'',
				contactpersonname: '',
				contact_no: '',
				price: '',
				sizeinguz: '',
				frontinfoot: '',
				lengthinfoot: '',
				constructed: 0,
				iskda: 0,
				description: ''
			},
			youtubeLink: '',
			youtubeLinks: [],
			contact_no: '',
			contact_nos: [],

		}
	},
	mounted() {
		this.initialize()
	},

	computed: {

	},

	methods: {
		initialize(){
			if(this.editproperty != null){
				this.form = this.editproperty
                this.youtubeLinks = this.editproperty.youtubelink
                this.contact_nos = this.editproperty.contact_no
			}
		},
		onSubmit: function () {
			console.log("this form data ", this.form)
			this.form.sizeinguz = parseFloat(this.form.sizeinguz)
			this.form.frontinfoot = parseFloat(this.form.frontinfoot)
			this.form.lengthinfoot = parseFloat(this.form.lengthinfoot)
			this.form.youtubelink = this.youtubeLinks
			this.form.contact_no = this.contact_nos
			axios.post('api/realstate/create', this.form)
				.then(response => {
					console.log(response.data);
					if (response.data.status) {
						this.cancel();
					}
				})
				.catch(error => {
					console.log(error);
				});
		},
		cancel() {
			this.form = new window.Form();
			this.youtubeLinks = [];
			this.contact_nos = [];
			if(this.editproperty!=null){
				this.$emit('close')
			}
		}, 
		saveYoutubeLink() {
			if (this.youtubeLink !== '') {
				this.youtubeLinks.push(this.youtubeLink);
				this.youtubeLink = '';
			}
		},
		removeYoutubeLink(index) {
			this.youtubeLinks.splice(index, 1);
		},
		saveContactNo() {
			if (this.contact_no !== '') {
				if(this.contact_no.length == 10){
					this.contact_no = '+91'+this.contact_no
				}
				this.contact_nos.push(this.contact_no);
				this.contact_no = '';
				console.log("contact nos ",this.contact_nos)
			}
		},
		removeContactNo(index) {
			this.contact_nos.splice(index, 1);
		},


	}
}

</script>
